import React from "react"
import { graphql } from "gatsby"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"
import styles from "../styles/templates/legal-template.module.scss"

import Flag1 from "../images/family-flag-1.svg"
import Flag2 from "../images/family-flag-2.svg"
import Flag3 from "../images/family-flag-3.svg"

class LegalTemplate extends React.Component {
  componentDidMount() {
    //add target=_blank to all anchor tags in bodyContainer
    // var anchors = document
    //   .getElementsByClassName(styles.bodyContainer)[0]
    //   .getElementsByTagName("a")
    // for (var i = 0; i < anchors.length; i++) {
    //   anchors[i].setAttribute("target", "_blank")
    //   anchors[i].setAttribute("rel", "noopener noreferrer")
    // }
  }
  render() {
    const { title, bodyText } = this.props.data.contentfulLegalTemplate
    return (
      <Layout>
        <Fade left>
          <div className={styles.flag1}>
            <Flag1 />
          </div>
        </Fade>
        <Fade left>
          <div className={styles.flag2}>
            <Flag2 />
          </div>
        </Fade>
        <Fade left>
          <div className={styles.flag3}>
            <Flag3 />
          </div>
        </Fade>
        <div className={styles.container}>
          <h1 className={styles.pageTitle}>{title}</h1>
          <div
            className={styles.bodyContainer}
            dangerouslySetInnerHTML={{
              __html: bodyText.childMarkdownRemark.html,
            }}
          />
        </div>
      </Layout>
    )
  }
}

export default LegalTemplate

export const query = graphql`
  query LegalTemplateBySlug($slug: String!) {
    contentfulLegalTemplate(slug: { eq: $slug }) {
      title
      slug
      bodyText {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
